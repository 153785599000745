import React, { useCallback, useEffect, useMemo } from 'react';
import { ClipLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { confirmAlert } from 'react-confirm-alert';
import {
    addParamsToUrl,
    goToState,
} from '../../../../global/helpers/url-parse.helper';
import { Root, Header, FilterPanel } from './family-list.styles';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {
    getLocationSearchParamByKey,
    makeGetIsShowLocalSpinner,
} from '../../../../global/redux/global/global.selectors';
import { useLocalSpinner } from '../../../../global/hooks/use-local-spinner';
import { LocalSpinnerKeysEnum } from '../../../../global/constants';
import { BottomPadding, LocalSpinner } from '../../../../global/components/main-wrap/main-wrap.styles';
import { Button } from '../../../../global/components/button';
import { useDebounce } from '../../../../global/hooks/use-debounce';
import { InfoItem } from '../../../../global/components/info-item';
import { TextInput } from '../../../../global/components/form-controls/text-input/text-input';
import { getFamilyList, getFamilyTotalCount } from '../../redux/family.selectors';
import { GetFamilyListQueryParams } from '../../types/get-family-list-query-params';
import { GetFamilyListBodyParams } from '../../types/get-family-list-body-params';
import { FamilyList as FamilyListModel } from '../../models/family-list.model';
import { deleteFamilyCard, updateFamily } from '../../redux/family.actions';
import { AuthService } from '../../../../auth/services/AuthService';
import { LinkA } from '../../../../global/theme/global.styles';

type FamilyItemProps = {
    item: FamilyListModel;
    queryParams: GetFamilyListQueryParams;
    bodyParams: GetFamilyListBodyParams
};

const FamilyItem = (props: FamilyItemProps) => {
    const dispatch = useDispatch();
    const localSpinnerRunner = useLocalSpinner(dispatch);
    const goToCard = useCallback(() => {
        goToState(`/family/${props.item.id}`);
    }, [props.item.id]);
    const goToEdit = useCallback(() => {
        goToState(`/family/${props.item.id}/edit`);
    }, [props.item.id]);
    const goToDelete = useCallback(() => {
        confirmAlert({
            title: 'Удаление',
            message: 'Вы уверены, что хотите удалить эту запись?',
            buttons: [
                {
                    label: 'Да',
                    onClick: async () => {
                        await localSpinnerRunner(deleteFamilyCard(props.item.id), LocalSpinnerKeysEnum.familyList);
                        localSpinnerRunner(updateFamily(props.queryParams, props.bodyParams), LocalSpinnerKeysEnum.familyList);
                    },
                },
                {
                    label: 'Нет',
                    onClick: () => null,
                },
            ],
        });
    }, [confirmAlert, props.item.id, props.queryParams, props.bodyParams]);
    return (
        <TableRow hover role="checkbox" tabIndex={-1} onDoubleClick={goToCard}>
            <TableCell align="left">{props.item.id}</TableCell>
            <TableCell align="left">
                <LinkA href={`#/family/${props.item.id}`}>
                    {props.item.name}
                </LinkA>
            </TableCell>
            <TableCell align="left">
                <EditIcon onClick={goToEdit} className="action-icon" />
                <DeleteIcon onClick={goToDelete} className="action-icon" />
            </TableCell>
        </TableRow>
    );
};

const FamilyList = () => {
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);
    const dispatch = useDispatch();
    const pageNo = useSelector(getLocationSearchParamByKey('pageNo'));
    const pageSize = useSelector(getLocationSearchParamByKey('pageSize'));
    const searchString = useSelector(getLocationSearchParamByKey('searchString'));
    const debounceSearchInputText = useDebounce(searchString);
    const familyList = useSelector(getFamilyList);
    const familyTotalCount = useSelector(getFamilyTotalCount);
    const localSpinnerRunner = useLocalSpinner(dispatch);
    const isShowSpinner = useSelector(
        makeGetIsShowLocalSpinner(LocalSpinnerKeysEnum.familyList),
    );

    const queryParams: GetFamilyListQueryParams = useMemo(() => ({
        pageNo: pageNo ? Number(pageNo) : 0,
        pageSize: pageSize ? Number(pageSize) : 10,
    }), [pageNo, pageSize]);

    const bodyParams: GetFamilyListBodyParams = useMemo(() => ({
        searchString: debounceSearchInputText || '',
    }), [debounceSearchInputText]);

    useEffect(() => {
        if (!showForAdmin) {
            goToState('/');
            return;
        }
    }, []);

    useEffect(() => {
        localSpinnerRunner(
            updateFamily(
                queryParams,
                bodyParams,
            ),
            LocalSpinnerKeysEnum.familyList,
        );
    }, [queryParams, bodyParams]);

    const pageNoValue = useMemo(
        () => (familyTotalCount ? (pageNo === null ? 0 : Number(pageNo)) : 0),
        [pageNo, familyTotalCount],
    );
    const pageSizeValue = useMemo(
        () =>
            familyTotalCount ? (pageSize === null ? 10 : Number(pageSize)) : 0,
        [pageSize, familyTotalCount],
    );
    const handleChangePage = useCallback(
        (
            event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
            page: number,
        ) => {
            addParamsToUrl([
                {
                    key: 'pageNo',
                    value: page,
                },
            ]);
        },
        [addParamsToUrl],
    );
    const handleChangeRowsPerPage = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            addParamsToUrl([
                {
                    key: 'pageNo',
                    value: 0,
                },
                {
                    key: 'pageSize',
                    value: event.target.value,
                },
            ]);
        },
        [addParamsToUrl],
    );
    const goToCreate = useCallback(() => {
        goToState('/family/create');
    }, []);

    const updateSearchString = useCallback((value: string | null) => {
        addParamsToUrl([
            {
                key: 'searchString',
                value,
            },
        ]);
    }, [addParamsToUrl]);

    const clearFilters = useCallback(() => {
        addParamsToUrl([
            {
                key: 'searchString',
                value: undefined,
            },
            {
                key: 'pageNo',
                value: 0,
            },
            {
                key: 'pageSize',
                value: 10,
            },
        ]);
    }, [addParamsToUrl]);

    return (
        <Root>
            <Header>
                <Button title="Создать" onClick={goToCreate} />
            </Header>
            <FilterPanel>
                <InfoItem
                    title="Поиск"
                    value={<TextInput
                        value={searchString}
                        onChange={updateSearchString}
                    />}
                />
                <Button className="clear-filter-button" title="Сбросить фильтры" onClick={clearFilters} />
            </FilterPanel>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Id</TableCell>
                                <TableCell align="left">Название</TableCell>
                                <TableCell align="left" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {familyList.map((family, index) => (
                                <FamilyItem
                                    item={family}
                                    key={index}
                                    queryParams={queryParams}
                                    bodyParams={bodyParams}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[3, 10, 25, 100]}
                    component="div"
                    count={familyTotalCount}
                    rowsPerPage={pageSizeValue}
                    page={pageNoValue}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Записей на странице"
                />
            </Paper>
            <ClipLoader loading={isShowSpinner} size={30} css={LocalSpinner} />
            <BottomPadding />
        </Root>
    );
};

export default FamilyList;
