import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MemberInfoCardEditProps } from '../../../../global/types/member-info-card-edit-props';
import { InfoLine } from '../../../../global/components/info-line';
import { InfoItem } from '../../../../global/components/info-item';
import { TextInput } from '../../../../global/components/form-controls/text-input/text-input';
import { TextInputMask } from '../../../../global/components/form-controls/text-input-mask/text-input-mask';
import Select from 'react-select';
import { Calendar } from 'primereact/calendar';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getMemberCard } from '../../redux/members.selectors';
import { useEventValue } from '../../../../global/hooks/use-event-value';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { MemberSex, MemberSexItem } from '../../models/member-sex.model';
import {
    MemberStatus,
    MemberStatusItem,
} from '../../models/member-status.model';
import { CreateMemberParams } from '../../types/create-member-params';
import { parseDateForApi } from '../../../../global/helpers/date.helper';
import { updateMemberCard } from '../../redux/members.actions';
import { useCheckNotSaveData } from '../../../../global/hooks/use-check-not-save-data';
import { TabIds } from '../../../../global/hooks/use-member-tabs';

const MemberInfoCardEdit = ({ id, isSaveCallback, setChangeInfo,
    saveTrigger, changedMainInfo, isValidCallback }: MemberInfoCardEditProps) => {
    const dispatch = useDispatch();
    const memberCard = useSelector(getMemberCard);
    const spinnerRunner = useSpinner(dispatch);
    const [firstName, setFirstName] = useState<string | null>(null);
    const [lastName, setLastName] = useState<string | null>(null);
    const [patronymic, setPatronymic] = useState<string | null>(null);
    const [phone, setPhone] = useState<string | null>(null);
    const [sex, setSex] = useState<MemberSexItem | null>(null);
    const [status, setStatus] = useState<MemberStatusItem | null>(null);
    const [birthDay, setBirthDay] = useState<Date | null>(null);
    const [birthPlace, setBirthPlace] = useState<string | null>(null);
    const [skills, setSkills] = useState<string | null>(null);
    const [repentanceYear, setRepentanceYear] = useState<string | null>(null);
    const [repentancePlace, setRepentancePlace] = useState<string | null>(null);
    const [baptismYear, setBaptismYear] = useState<string | null>(null);
    const [witnessesDate, setWitnessesDate] = useState<Date | null>(null);
    const [adoptedDate, setAdoptedDate] = useState<Date | null>(null);
    const [disposalDate, setDisposalDate] = useState<Date | null>(null);
    const [disciplineDate, setDisciplineDate] = useState<Date | null>(null);
    const [disciplineReason, setDisciplineReason] = useState<string | null>(
        null,
    );
    const [notCongratulation, setNotCongratulation] = useState<boolean>(false);
    const [rbcBaptism, setRbcBaptism] = useState<boolean>(false);
    const [completeAu, setCompleteAU] = useState<boolean>(false);

    const handleBirthDateChange = useEventValue(setBirthDay);
    const handleWitnessesDateChange = useEventValue(setWitnessesDate);
    const handleAdoptedDateChange = useEventValue(setAdoptedDate);
    const handleDisposalDateChange = useEventValue(setDisposalDate);

    useEffect(() => {
        if (!saveTrigger || !changedMainInfo) {
            return;
        }
        saveCallback();
    }, [saveTrigger]);

    useEffect(() => {
        if (memberCard) {
            setFirstName(memberCard.firstName);
            setLastName(memberCard.lastName);
            setPatronymic(memberCard.patronymic);
            setPhone(memberCard.phone);
            setSex(memberCard.sex);
            setStatus(memberCard.status);
            setBirthDay(memberCard.birthDay);
            setBirthPlace(memberCard.birthPlace);
            setSkills(memberCard.skills);
            setRbcBaptism(!!memberCard.rbcBaptism);
            setRepentanceYear(`${memberCard.repentanceYear ?? ''}`);
            setRepentancePlace(memberCard.repentancePlace);
            setBaptismYear(`${memberCard.baptismYear ?? ''}`);
            setWitnessesDate(memberCard.witnessesDate);
            setAdoptedDate(memberCard.adoptedDate);
            setDisposalDate(memberCard.disposalDate);
            setNotCongratulation(!!memberCard.notCongratulation);
            setCompleteAU(!!memberCard.completeAu);
            setDisciplineDate(memberCard.disciplineDate);
            setDisciplineReason(memberCard.disciplineReason);
        }
    }, [memberCard]);

    const statusOptions = useMemo(() => Object.values(MemberStatus), []);
    const sexOptions = useMemo(() => Object.values(MemberSex), []);
    const isValidForm = useMemo(
        () =>
            !!firstName &&
            !!lastName &&
            !!phone &&
            phone.indexOf('_') === -1 &&
            !!status &&
            !!sex,
        [firstName, lastName, phone, status, sex],
    );

    const isDiscipline = useMemo(
        () =>
            [
                MemberStatus.ON_REMARK.value,
                MemberStatus.EXCOMMUNICATED.value,
            ].some((v) => status?.value === v),
        [status?.value],
    );

    const params: CreateMemberParams | null = useMemo(() => {
        if (
            !id ||
            !firstName ||
            !lastName ||
            !phone ||
            phone.indexOf('_') !== -1 ||
            !status ||
            !sex
        ) {
            return null;
        }
        return {
            firstName,
            lastName,
            patronymic,
            phone,
            status: status.value,
            sex: sex.value,
            bDay: parseDateForApi(birthDay),
            birthPlace,
            skills,
            repentanceYear,
            repentancePlace,
            baptismYear,
            witnessesDate: parseDateForApi(witnessesDate),
            adoptedDate: parseDateForApi(adoptedDate),
            disposalDate: parseDateForApi(disposalDate),
            disciplineDate:
                isDiscipline && status.value !== memberCard?.status.value
                    ? parseDateForApi(new Date())
                    : parseDateForApi(disciplineDate),
            disciplineReason,
            externalId: null,
            notCongratulation: !!notCongratulation,
            completeAu: !!completeAu,
            rbcBaptism,
        };
    }, [
        isDiscipline,
        firstName,
        lastName,
        patronymic,
        phone,
        status,
        sex,
        birthDay,
        birthPlace,
        skills,
        repentanceYear,
        repentancePlace,
        baptismYear,
        witnessesDate,
        adoptedDate,
        disposalDate,
        notCongratulation,
        completeAu,
        rbcBaptism,
        disciplineDate,
        disciplineReason,
    ]);

    const { clearCallback } = useCheckNotSaveData({
        data: params, 
        isNotSaveCallback: (isNotSaved) => {
            if (isNotSaved) {
                setChangeInfo(TabIds.MainInfo, true);
                isSaveCallback(false);
            } else {
                isSaveCallback(true);
                setChangeInfo(TabIds.MainInfo, false);
            }
        },
    });

    useEffect(() => {
        isValidCallback(isValidForm); 
    }, [isValidForm]);

    const saveCallback = useCallback(() => {
        if (!params || !id) {
            return;
        }
        
        spinnerRunner(updateMemberCard(id, params));
        clearCallback();
    }, [
        params,
        id,
        clearCallback,
    ]);

    const toggleNotCongratulation = useCallback(() => {
        setNotCongratulation(!notCongratulation);
    }, [notCongratulation]);

    const toggleRbcBatism = useCallback(() => {
        setRbcBaptism(!rbcBaptism);
    }, [rbcBaptism]);

    const toggleCompleteAU = useCallback(() => {
        setCompleteAU(!completeAu);
    }, [completeAu]);
    return (
        <div>
            <InfoLine>
                <InfoItem
                    title="Фамилия *"
                    value={
                        <TextInput value={lastName} onChange={setLastName} />
                    }
                />
                <InfoItem
                    title="Имя *"
                    value={
                        <TextInput value={firstName} onChange={setFirstName} />
                    }
                />
                <InfoItem
                    title="Отчество"
                    value={
                        <TextInput
                            value={patronymic}
                            onChange={setPatronymic}
                        />
                    }
                />
                {/* <InfoItem
                    title="Внешний id (из гугл.таблицы)"
                    value={<TextInput
                        value={externalId}
                        onChange={setExternalId}
                    />}
                /> */}
            </InfoLine>
            <InfoLine>
                <InfoItem
                    title="Телефон *"
                    value={
                        <TextInputMask
                            value={phone}
                            mask="+99999999999"
                            onChange={setPhone}
                        />
                    }
                />
                <InfoItem
                    title="Церковный статус *"
                    value={
                        <Select
                            options={statusOptions}
                            defaultValue={status}
                            value={status}
                            onChange={setStatus}
                            isClearable
                        />
                    }
                />
            </InfoLine>

            {!!isDiscipline && (
                <InfoLine>
                    <InfoItem
                        title="Дата дисциплины"
                        value={
                            <Calendar
                                locale={'ru'}
                                className="local-datapicker"
                                value={new Date()}
                                disabled
                                dateFormat={'dd.mm.yy'}
                            />
                        }
                    />
                </InfoLine>
            )}
            {!!isDiscipline && (
                <InfoLine>
                    <InfoItem
                        title="Причина дисциплины"
                        value={
                            <TextInput
                                value={disciplineReason}
                                onChange={setDisciplineReason}
                                asTextarea
                            />
                        }
                    />
                </InfoLine>
            )}
            <InfoLine>
                <FormControlLabel
                    className="filter-item filter-item-checkbox"
                    control={
                        <Checkbox
                            checked={rbcBaptism}
                            onClick={toggleRbcBatism}
                        />
                    }
                    label="Крещён в РБЦ"
                />
            </InfoLine>
            <InfoLine>
                <FormControlLabel
                    className="filter-item filter-item-checkbox"
                    control={
                        <Checkbox
                            checked={notCongratulation}
                            onClick={toggleNotCongratulation}
                        />
                    }
                    label="Не поздравлять с днём рождения"
                />
            </InfoLine>
            <InfoLine>
                <InfoItem
                    title="Пол *"
                    value={
                        <Select
                            options={sexOptions}
                            defaultValue={sex}
                            value={sex}
                            onChange={setSex}
                            isClearable
                        />
                    }
                />
                <InfoItem
                    title="Дата рождения"
                    value={
                        <Calendar
                            locale={'ru'}
                            className="local-datapicker"
                            value={birthDay}
                            onChange={handleBirthDateChange}
                            dateFormat={'dd.mm.yy'}
                        />
                    }
                />
                <InfoItem
                    title="Место рождения"
                    value={
                        <TextInput
                            value={birthPlace}
                            onChange={setBirthPlace}
                        />
                    }
                />
            </InfoLine>
            <InfoItem
                title="Способности"
                value={
                    <TextInput value={skills} onChange={setSkills} asTextarea />
                }
            />
            <InfoLine>
                <InfoItem
                    title="Год покаяния"
                    value={
                        <TextInput
                            value={repentanceYear}
                            onChange={setRepentanceYear}
                        />
                    }
                />
                <InfoItem
                    title="Место покаяния"
                    value={
                        <TextInput
                            value={repentancePlace}
                            onChange={setRepentancePlace}
                        />
                    }
                />
                <InfoItem
                    title="Год крещения"
                    value={
                        <TextInput
                            value={baptismYear}
                            onChange={setBaptismYear}
                        />
                    }
                />
            </InfoLine>
            <InfoLine>
                <InfoItem
                    title="Дата рассказывания свидетельства"
                    value={
                        <Calendar
                            locale={'ru'}
                            className="local-datapicker"
                            value={witnessesDate}
                            onChange={handleWitnessesDateChange}
                            dateFormat={'dd.mm.yy'}
                        />
                    }
                />
                <InfoItem
                    title="Дата принятия в члены церкви"
                    value={
                        <Calendar
                            locale={'ru'}
                            className="local-datapicker"
                            value={adoptedDate}
                            onChange={handleAdoptedDateChange}
                            dateFormat={'dd.mm.yy'}
                        />
                    }
                />
                <InfoItem
                    title="Дата отбытия"
                    value={
                        <Calendar
                            locale={'ru'}
                            className="local-datapicker"
                            value={disposalDate}
                            onChange={handleDisposalDateChange}
                            dateFormat={'dd.mm.yy'}
                        />
                    }
                />
            </InfoLine>
            <InfoLine>
                <FormControlLabel
                    className="filter-item filter-item-checkbox"
                    control={
                        <Checkbox
                            checked={completeAu}
                            onClick={toggleCompleteAU}
                        />
                    }
                    label="Прошёл АУ"
                />
            </InfoLine>
        </div>
    );
};

export default MemberInfoCardEdit;
