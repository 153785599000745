import { Calendar } from 'primereact/calendar';
import { FormEvent } from 'primereact/ts-helpers';
import React from 'react';
import { Root } from './date-period-filter.styles';

type DatePeriodItem = {
    label: string;
    value: Date | null;
};

type DatePeriodFilterProps = {
    from: DatePeriodItem;
    to: DatePeriodItem;
    updateCallback: (params: { from: Date | null; to: Date | null }) => void;
};

const DatePeriodFilter: React.FC<DatePeriodFilterProps> = ({
    from,
    to,
    updateCallback,
}) => {
    const handleFromChange = (e: FormEvent<Date>) => {
        updateCallback({
            from: e.value ?? null,
            to: to.value,
        });
    };

    const handleToChange = (e: FormEvent<Date>) => {
        updateCallback({
            from: from.value,
            to: e.value ?? null,
        });
    };
    return (
        <Root>
            <Calendar
                showButtonBar
                placeholder={from.label}
                locale={'ru'}
                className="local-datapicker"
                value={from.value}
                onChange={handleFromChange}
                dateFormat={'dd.mm.yy'}
                headerTemplate={() => (
                    <span style={{ fontWeight: 'bold' }}>{from.label}</span>
                )}
            />
            <Calendar
                showButtonBar
                placeholder={to.label}
                locale={'ru'}
                className="local-datapicker"
                value={to.value}
                onChange={handleToChange}
                dateFormat={'dd.mm.yy'}
                headerTemplate={() => (
                    <span style={{ fontWeight: 'bold' }}>{to.label}</span>
                )}
            />
        </Root>
    );
};
export default DatePeriodFilter;
