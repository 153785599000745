import * as t from 'io-ts';
import { FamilyList, FamilyListType } from '../../family/models/family-list.model';
import { MemberStatus, MemberStatusItem } from './member-status.model';

export const MemberListType = t.type({
    id: t.number,
    firstName: t.union([t.string, t.null]),
    lastName: t.union([t.string, t.null]),
    phone: t.union([t.string, t.undefined, t.null]),
    status: t.union([t.string, t.undefined, t.null]),
    family: t.union([FamilyListType, t.undefined, t.null]),
    confirmationIsFalse: t.union([t.boolean, t.undefined]),
    outsideGroups: t.union([t.boolean, t.undefined]),
    outsideMinistries: t.union([t.boolean, t.undefined]),
    donatedRecently: t.union([t.boolean, t.undefined, t.null]),
    pastorNote: t.union([t.string, t.null, t.undefined]),
    rbcBaptism: t.union([t.boolean, t.null, t.undefined]),
});

export type MemberListDTO = t.TypeOf<typeof MemberListType>;

class MemberList {
    id: number;
    firstName: string;
    lastName: string;
    phone: string;
    status: MemberStatusItem;
    family: FamilyList | null;
    confirmationIsFalse: boolean;
    outsideGroups: boolean;
    donatedRecently: boolean;
    outsideMinistries: boolean;
    pastorNote: string;
    rbcBaptism: boolean;

    constructor(dto: MemberListDTO) {
        this.id = dto.id;
        this.firstName = dto.firstName || '';
        this.lastName = dto.lastName || '';
        this.phone = dto.phone || '';
        this.pastorNote = dto.pastorNote || '';
        this.status = dto.status && MemberStatus[dto.status] ? MemberStatus[dto.status] : MemberStatus.GUEST;
        this.donatedRecently = !!dto.donatedRecently;
        this.rbcBaptism = !!dto.rbcBaptism;
        this.confirmationIsFalse = !!dto.confirmationIsFalse && (dto.status === MemberStatus.RBC.value || dto.status === MemberStatus.ON_REMARK.value);
        this.outsideGroups = !!dto.outsideGroups && (dto.status === MemberStatus.RBC.value || dto.status === MemberStatus.ON_REMARK.value);
        this.outsideMinistries = !!dto.outsideMinistries && (dto.status === MemberStatus.RBC.value || dto.status === MemberStatus.ON_REMARK.value);
        this.family = dto.family ? new FamilyList(dto.family) : null;
    }

    get name() {
        let result: string = '';
        if (!this) {
            return result;
        }
        if (this.lastName) {
            result = `${this.lastName}`;
        }
        if (this.firstName) {
            result = `${result}${result ? ' ' : ''}${this.firstName}`;
        }
        return result;
    }
}

export { MemberList };
