import React, { FC, memo, useState, MouseEvent, useContext, useMemo, useCallback, useRef, useEffect } from 'react';
import {
    ActiveBorder,
    CloseIconWrapper,
    FileNameEditingInput,
    FileNameEditingInputInner,
    RedFlag,
    TabWrapper,
    Title,
} from './tab.styles';
import { ReactComponent as CloseIcon } from '../icons/close.svg';
import { ContextMenuItem, ContextMenuSetterContext } from '../../context-menu/context';
import cn from 'classnames';
import { CallbackTabParam } from '../tab-bar';
import { getTextMetrics } from '../../../helpers/elements';
import { LocalAnyType } from '../../../helpers/type.helper';

interface TabProps {
    id?: string;
    domId?: string;
    index: number;
    title: string;
    isEdit?: boolean;
    isActive?: boolean;
    isFixed?: boolean;
    isAvailableClose?: boolean;
    isAvailableRename?: boolean;
    isNotSaved?: boolean;
    isPrimaryTabs?: boolean;
    isFlex?: boolean;
    isVerticalLayout?: boolean;
    borderRadiusTop?: string;
    onClick?: (params: CallbackTabParam) => void;
    onEnter?: (params: CallbackTabParam) => void;
    onClose?: (params: CallbackTabParam) => void;
    onContextMenu?: (params: CallbackTabParam) => ContextMenuItem[];
    icon?: LocalAnyType;
}

export const Tab: FC<TabProps> = memo(
    ({
        id,
        domId,
        title,
        index,
        isEdit,
        isActive,
        isFixed,
        isAvailableClose,
        isAvailableRename,
        isNotSaved,
        isPrimaryTabs,
        isFlex,
        isVerticalLayout,
        borderRadiusTop,
        onClick,
        onEnter,
        onClose,
        onContextMenu,
        icon,
    }) => {
        const [tabTitle, setTabTitle] = useState(title);
        const [edit, setEdit] = useState(isEdit);
        const inputRef = useRef<HTMLInputElement>(null);

        const { updateContextMenuCallback } = useContext(ContextMenuSetterContext);

        useEffect(() => {
            setTabTitle(title);
        }, [title]);

        useEffect(() => {
            if (edit && inputRef.current) {
                inputRef.current.select();
            }
        }, [edit, inputRef]);

        const startEdit = () => {
            if (isAvailableRename) {
                setEdit(true);
            }
        };
        const stopEdit = () => setEdit(false);

        const onDisableEdit = useCallback(() => {
            if (!title) {
                onClose?.({ id });
                return;
            }
            setEdit(false);
        }, [title, setEdit]);

        const onChangeTabTitle = useCallback(
            (value: string | null) => {
                setTabTitle(value || '');
            },
            [setTabTitle],
        );

        const onEnterHandler = useCallback(() => {
            if (!tabTitle.trim()) {
                return;
            }
            stopEdit();
            title !== tabTitle &&
                onEnter?.({
                    id,
                    index,
                    newTitle: tabTitle,
                });
        }, [id, tabTitle, title, index]);

        const onContextMenuCallback = useCallback(
            (e: MouseEvent) => {
                e.preventDefault();
                e.stopPropagation();
                onClick?.({ id, index });

                let items: ContextMenuItem[] = [];

                if (isAvailableRename) {
                    items.push({
                        title: 'Переименовать',
                        onClick: startEdit,
                    });
                }

                if (isAvailableClose && !isFixed) {
                    items.push({
                        title: 'Закрыть',
                        onClick: () => {
                            onClose?.({ id, index });
                        },
                    });
                }

                if (onContextMenu) {
                    items = items.concat(onContextMenu({ index, id }));
                }

                if (!items.length) {
                    return;
                }

                updateContextMenuCallback({
                    items,
                    clickCoordinates: { x: e.clientX, y: e.clientY },
                    anchorElement: e.target,
                });
            },
            [updateContextMenuCallback, onContextMenu, id, index, onClick],
        );

        const onCloseIconClick = useCallback(
            (e: MouseEvent) => {
                e.stopPropagation();
                edit ? onDisableEdit() : onClose?.({ id, index });
            },
            [edit, id, index, onClose, onDisableEdit],
        );

        const onBlur = useCallback(() => {
            if (!tabTitle.trim()) {
                onDisableEdit();
                return;
            }
            stopEdit();
            title !== tabTitle &&
                onEnter?.({
                    id,
                    index,
                    newTitle: tabTitle,
                });
        }, [title, tabTitle, id, index]);

        const onClickHandler = useCallback(() => onClick?.({ id, index }), [onClick, id, index]);

        const visibleTabTitle = useMemo(() => `${tabTitle}`, [tabTitle, isNotSaved]);

        const calcInputWidth = useMemo(() => {
            return {
                widthLetter: getTextMetrics(tabTitle, '14px \'Consolas\', sans-serif')?.width || 0,
                letterCount: tabTitle.length,
            };
        }, [tabTitle]);

        const closeIconWrapperClassName = useMemo(
            () =>
                cn('close-icon-wrapper', {
                    show: !!isActive,
                }),
            [isActive],
        );

        const wrapperClass = useMemo(
            () =>
                cn({
                    'flex-styles': isFlex && !isVerticalLayout,
                    'primary-tab-styles': isPrimaryTabs,
                    'vertical-styles': isVerticalLayout,
                    active: isActive,
                }),
            [isFlex, isPrimaryTabs, isActive, isVerticalLayout],
        );

        return (
            <TabWrapper
                id={domId}
                isActive={isActive}
                isFixed={isFixed}
                isFirst={index === 0}
                borderRadiusTop={borderRadiusTop}
                onClick={onClickHandler}
                onContextMenu={onContextMenuCallback}
                onDoubleClick={startEdit}
                className={wrapperClass}
                isVerticalLayout={isVerticalLayout}
            >
                {icon}
                {edit ? (
                    <FileNameEditingInput
                        width={calcInputWidth.widthLetter}
                        letterCount={calcInputWidth.letterCount}
                        value={tabTitle}
                        style={FileNameEditingInputInner}
                        autofocus={true}
                        onChange={onChangeTabTitle}
                        onEnter={onEnterHandler}
                        onBlur={onBlur}
                    />
                ) : (
                    <Title isPrimaryTabs={isPrimaryTabs}>{visibleTabTitle}</Title>
                )}
                {!isFixed && isAvailableClose && (
                    <CloseIconWrapper
                        className={closeIconWrapperClassName}
                        onClick={onCloseIconClick}
                        isPrimaryTabs={isPrimaryTabs}
                    >
                        <CloseIcon />
                    </CloseIconWrapper>
                )}
                <ActiveBorder isFixed={isFixed} isActive={isActive} isPrimaryTabs={isPrimaryTabs} />
                {isNotSaved && <RedFlag >*</RedFlag>}
            </TabWrapper>
        );
    },
);
