import { useCallback, useMemo, useState } from 'react';

export enum TabIds {
    MainInfo = 'MAIN_INFO',
    Membership = 'MEMBERSHIP'
}

const tabItems = [{
    title: 'Общая информация',
    id: TabIds.MainInfo,
},
{
    title: 'Членство',
    id: TabIds.Membership,
}];

export const useMemberTabs = () => {
    const [selectedTab, setSelectedTab] = useState<string>(tabItems[0].id);
    const [changedMainInfo, seChangedMainInfo] = useState(false);
    const [changedMembership, setChangedMembership] = useState(false);
    const isSelectedMainInfo = selectedTab === TabIds.MainInfo;
    const isSelectedAdoptedInfo = selectedTab === TabIds.Membership;

    const tabs = useMemo(() => (
        tabItems.map(el => ({
            ...el,
            isActive: el.id === selectedTab,
            isNotSaved: el.id === TabIds.MainInfo ? changedMainInfo : changedMembership,
        }))
    ), [selectedTab, changedMainInfo, changedMembership]);

    const onTabClick = useCallback((params) => {
        if (params.id) {
            setSelectedTab(params.id);
        }
    }, []);

    const setChangeInfo = useCallback((tabId, state) => {
        if (tabId === TabIds.MainInfo) {
            seChangedMainInfo(state);
        } else if (tabId === TabIds.Membership) {
            setChangedMembership(state);
        }
    }, []);
    

    return {
        tabs,
        onTabClick,
        isSelectedMainInfo,
        isSelectedAdoptedInfo,
        setChangeInfo,
        changedMainInfo,
        changedMembership,
    };
};
