import { useCallback, useEffect, useState } from 'react';

export const useCheckNotSaveData = <T>(params: {
    data: T,
    isNotSaveCallback: (value: boolean) => void,
}) => {
    const {
        data,
        isNotSaveCallback,
    } = params;
    const [lastSaveParams, setLastSaveParams] = useState<string | null>(null);
    
    useEffect(() => {
        if (!data) {
            return;
        }
        const timer = setTimeout(() => {
            const strParams = JSON.stringify(data);
            
            if (!lastSaveParams) {
                setLastSaveParams(strParams);
                return;
            }
            isNotSaveCallback(lastSaveParams !== strParams);
        }, 500);

        return () => clearTimeout(timer);
    }, [data]);

    const clearCallback = useCallback(() => {
        isNotSaveCallback(false);
        setLastSaveParams(JSON.stringify(data));
    }, [data, isNotSaveCallback]);

    return {
        clearCallback,
    };
};