import { useCallback, useState } from 'react';

export const useSaveData = () => {
    const [saveTrigger, setSaveTrigger] = useState<number>(0);

    const saveCallback = useCallback(() => {
        setSaveTrigger((prev) => prev + 1);
    }, []);

    return { saveCallback, saveTrigger };
};
