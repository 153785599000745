import styled, { css } from 'styled-components/macro';

const Root = styled.div`
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .filter-item {
        margin-right: 24px;
        margin-bottom: 12px;
    }
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 12px;

    & > div {
        margin-right: 12px;
    }
`;

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 12px;
    margin-bottom: 12px;
`;

const TabWrap = styled.div<{ hide?: boolean }>`
    ${({ hide }) => hide ? css`
        display: none;
    ` : ''}
`;

export { Root, Header, Footer, TabWrap };
