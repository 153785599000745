import styled from 'styled-components/macro';

const Root = styled.div`
    .local-datapicker {
        width: 200px;
        margin: 10px;
    }
`;

export { Root };
