import styled from 'styled-components/macro';
import { colors, defaultShadow, palette } from '../../styles/variables';

const Root = styled.div`
    width: 100%;
    min-height: 50px;
    padding: 0 12px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid ${colors.borderColor};

    .logout-icon {
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }

    .menu-icon {
        margin-right: 12px;
        cursor: pointer;

        &.is-open {
            path {
                fill: ${colors.buttonStaticBack};
            }
        }
    }
`;

const Title = styled.div`
    flex-grow: 1;
    font-weight: 600;
    font-size: 20px;
    margin-right: 24px;
`;

const UserName = styled.div`
    margin-right: 12px;
    font-size: 16px;
    cursor: pointer;
    color: ${palette.blue};
`;

const Menu = styled.div`
    position: fixed;
    z-index: 100;
    top: 55px;
    left: 5px;
    min-width: 100px;
    max-width: calc(100vw - 10px);
    min-height: 50px;
    max-height: calc(100vh - 60px);
    background: ${colors.white};
    ${defaultShadow};
    border-radius: 4px;
    padding: 16px 0;
`;

const MenuItem = styled.div`
    width: 100%;
    padding: 4px 16px;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 14px;

    &:hover {
        background: ${colors.buttonHoverBack};
        color: ${colors.white};
    }

    &.is-select {
        background: ${colors.buttonHoverBack};
        color: ${colors.white};
    }
`;

export { Root, Title, UserName, Menu, MenuItem };
