import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components/macro';
import { palette } from '../styles/variables';
import { scrollStyle } from '../styles/shared';

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    box-sizing: border-box;
  }
  
  body {
    background: #fafafa;
    font: 13px/1.231 sans-serif;
  }

  iframe {
    z-index: -1 !important;
    position: absolute !important;
    top: -100000000000000px !important;
    left: -100000000000000px !important;
  }

  table thead th {
    font-weight: 600 !important;
  }
  
  .local-button {
        height: 38px;
        margin-top: 18px;
  }

  .local-datapicker {
    height: 38px;
    width: 157px;
    margin-right: 0;

    .react-date-picker__wrapper {
      border: 1px solid hsl(0, 0%, 80%);
      border-radius: 4px;
      height: 38px;
      position: relative;
      top: -2px;
      padding: 0 8px;
    }
  }

  .ReactModal__Overlay {
    z-index: 10;
  }

  .ReactModal__Content {
    display: flex;
    justify-content: center;
    height: min-content;
    width: max-content;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
        
    @media (max-width: 950px) {
        max-width: 90%;
    } 
  }

  * {
      ${scrollStyle};
  }
`;

export const LinkA = styled.a`
  cursor: pointer;
  color: ${palette.blue};
`;

export default GlobalStyle;
