import React from 'react';
import { Root } from '../../pages/card/member-card.styles';
import { InfoItem } from '../../../../global/components/info-item';
import { InfoLine } from '../../../../global/components/info-line';
import { useSelector } from 'react-redux';
import { getAdoptedInfoCard } from '../../redux/members.selectors';
import { MemberItem } from '../../../family/pages/card/family-card.styles';
import { AdoptedInfoCardProps } from '../../../../global/types/adopted-info-card-props';

function AdoptedInfoCard({ ov = false }: AdoptedInfoCardProps) {
    const adoptedInfoCard = useSelector(getAdoptedInfoCard);

    if (adoptedInfoCard && ov) {
        return (
            <Root>
                <div>
                    <InfoLine>
                        <InfoItem
                            title="Cсылка на свидетельство "
                            value={
                                <div>
                                    {adoptedInfoCard.testimonyLink && (
                                        <a href={adoptedInfoCard.testimonyLink}>
                                            {adoptedInfoCard.testimonyLink}
                                        </a>
                                    )}
                                </div>
                            }
                        />
                    </InfoLine>
                </div>
            </Root>
        );
    }

    if (adoptedInfoCard) {
        return (
            <Root>
                <div>
                    <InfoLine>
                        <InfoItem
                            title="Cтатус открепительного письма "
                            value={adoptedInfoCard.absenteeLetterStatus?.label || ''}
                        />
                        <InfoItem
                            title="Статус свидетельства "
                            value={adoptedInfoCard.testimonyStatus?.label || ''}
                        />
                        <InfoItem
                            title="Cтатус доработки свидетельства "
                            value={adoptedInfoCard.testimonyRevision?.label || ''}
                        />
                        <InfoItem
                            title="Cсылка на свидетельство "
                            value={<div>
                                {
                                    adoptedInfoCard.testimonyLink &&
                                    <a href={adoptedInfoCard.testimonyLink}>{adoptedInfoCard.testimonyLink}</a>
                                }
                            </div>}
                        />
                    </InfoLine>
                    <InfoLine>
                        <InfoItem
                            title="Дата собеседования "
                            value={adoptedInfoCard.interviewDate}
                            dateFormat={'DD-MM-YYYY'}
                        />
                        <InfoItem
                            title="Время собеседования "
                            value={adoptedInfoCard.interviewTime}
                        />
                        <InfoItem
                            title="Результат собеседования "
                            value={adoptedInfoCard.interviewResult?.label || ''}
                        />
                    </InfoLine>
                    <InfoLine>
                        <InfoItem
                            title="В каком служении находится "
                            value={adoptedInfoCard.ministryInfo}
                        />
                        <InfoItem
                            title="Ответственный лидер"
                            value={<div>
                                {
                                    adoptedInfoCard.personalLeader &&
                                    <a href={`#/member/${adoptedInfoCard.personalLeader.id}`}>{adoptedInfoCard.personalLeader.name}</a>
                                }
                            </div>}
                        />
                        <InfoItem
                            title="Кто представляет церкви"
                            value={<div>
                                {
                                    adoptedInfoCard.presentForChurch &&
                                    <a href={`#/member/${adoptedInfoCard.presentForChurch.id}`}>{adoptedInfoCard.presentForChurch.name}</a>
                                }
                            </div>}
                        />
                        <InfoItem
                            title="Собеседующие пастора"
                            value={<div>
                                {
                                    adoptedInfoCard.interviewPastors.map(el =>
                                        <MemberItem key={el.id}>
                                            <a href={`#/member/${el.id}`}>{el.name}</a>
                                        </MemberItem>
                                    )
                                }
                            </div>}
                        />
                    </InfoLine>
                </div>
            </Root>
        );
    }
    return null;
    
}

export default AdoptedInfoCard;
